* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");
body {
  /* background: #151515; */
  /* height: 100vh; */
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  font-family: "Josefin Sans", sans-serif;
  background: #fff url(./image/cool-background.png);
  background-repeat: repeat;
  background-size: auto;
  background-repeat: repeat;
  background-size: auto;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
  background-attachment: fixed;
}
.MuiButton-root {
  font-family: "Aleo", serif !important;
}
html {
  height: 100%;
}
.banner_titile_newfont {
  font-family: "Satisfy", cursive !important;
  text-align: center;
  color: #fff;
  font-size: 50px;
}
.hedear-title {
  font-family: "Lalezar", cursive;
  font-size: 100px;
  text-align: center;
  color: #0048ac;
  padding: 31px 0px;
  text-shadow: 1px 4px 1px #228bdf;
}
header nav.bg-light {
  padding: 16px !important;
  background: transparent !important;
  padding: 0 !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
  font-size: 20px;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}
/* body::before {
  content: "";
  position: absolute;
  background-image: url(./image/bg-mariuana.png);
  height: 100%;
  width: 540px;
  background-size: contain;
  background-repeat: no-repeat;
} */
/* body::after {
  content: "";
  position: absolute;
  background-image: url(./image/bg-mariuana-2.png);
  height: 381px;
  width: 540px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 0;
  bottom: -72px;
} */
.banner-sedcrtio img {
  width: 200px;
  margin: 0 auto;
  display: block;
  margin-bottom: 41px;
}
.banner-sedcrtio {
  height: 100vh;
  display: grid;
  place-items: center;
}
.card_class_new {
  background: #007f36;
  padding: 50px;
  border-radius: 19px;
  margin-bottom: 50px;
  /* border: 4px solid #fff; */
}
main {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.card_class_new h4 {
  font-family: "Satisfy", cursive !important;
  text-align: center;
  font-size: 66px;
  margin-bottom: 49px;
  color: #fff;
  margin-top: -92px;
}
.card_class_new p {
  text-align: center;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.3px;
  padding: 11px 0px;
}

.card_class_new .social {
  position: absolute;
  bottom: 0;
  right: -25px;
}
.card_class_new .social ul {
  list-style: none;
  display: flex;
  flex-direction: column;
}
.card_class_new .social a {
  color: #000;
  background: #fff;
  display: grid;
  width: 50px;
  height: 50px;
  place-items: center;
  font-size: 28px;
  border-radius: 50%;
  color: #007f36;
  margin-bottom: 10px;
}
footer h5 {
  text-align: center;
  color: #fff;
  letter-spacing: 0.3px;
  font-size: 28px;
}
footer p {
  text-align: center;
  color: #ddd;
}

footer {
  position: relative;
}
/* footer::after {
  position: absolute;
  content: "";
  width: 400px;
  background: url(./image/bg-mariuana-2.png);
  height: 282px;
  right: 0;
  top: 0;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: -18px;
} */

.navbar-brand img {
  width: 219px;
}
/* 
.section_clip {
  background: #fff;
  padding-top: 304px;
  clip-path: polygon(0 24%, 100% 0, 100% 100%, 0% 100%);
  padding-bottom: 100px;
} */
.abut-content h4 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 30px;
  color: #fff;
  text-shadow: 0px 1px 10px #2d2d2d5c;
}
.abut-content p {
  font-size: 19px;
  color: #fff;
  text-align: center;
  text-shadow: 0px 1px 10px #2d2d2d5c;
}

footer ul {
  list-style: none;
  display: flex;
  /* justify-content: space-between; */
  /* align-items: unsafe; */
  margin: auto;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
}
footer ul li {
  padding: 10px 0;
  color: #fff;
}
footer ul li i {
  font-size: 21px;
  margin-right: 8px;
}
footer img {
  width: 202px;
  margin: 16px 0;
}

footer p {
  margin: 0;
  padding-bottom: 26px;
}
footer {
  /* background: #eeeeee; */
  padding: 30px 0px;
  padding-bottom: 0;
  margin-top: 40px;
}

.section_img_new {
  background-image: url(./image/of-women.jpg);
}

.main-content h1 {
  font-size: 63px;
  line-height: 90px;
  padding: 100px 0px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 2px;
  text-shadow: 0px 1px 10px #2d2d2d5c;
}
.cardneww {
  height: 500px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 15px;
  overflow: hidden;
  border: none;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.img-banner img {
  object-fit: cover;
  background-size: contain;
  width: 100%;
  height: 500px;
}
.img-banner {
  height: 500px;
  overflow: hidden;
}
.overfleee {
  height: 100vh;
  padding: 60px 0px;
}
.abut-content {
  /* background: #fff; */
  padding: 20px 45px;
  border-radius: 73px;
}

@media (max-width: 768px) {
  .hedear-title {
    font-size: 62px;
  }
  .section_clip {
    /* background: #fff; */
    padding-top: 25px;
    clip-path: none;
    padding-bottom: 100px;
  }
  footer img {
    width: 202px;
    margin: 16px auto;
    display: block;
  }
  footer ul {
    align-items: center;
  }
  footer p {
    font-size: 14px;
    margin-top: 20px;
  }
  .overfleee {
    height: 100%;
    padding: 60px 0px;
  }
  .main-content h1 {
    font-size: 45px;
    line-height: 64px;
    text-align: center;
  }
}
