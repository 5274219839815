.header-blue {
  background: linear-gradient(135deg, #fe2929, #ffb3c5);
  background-color: #184e8e;
  padding-bottom: 80px;
  font-family: "Source Sans Pro", sans-serif;
}

@media (min-width: 768px) {
  .header-blue {
    padding-bottom: 120px;
  }
}

.header-blue .navbar {
  background: transparent;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #fff;
  border-radius: 0;
  box-shadow: none;
  border: none;
}

@media (min-width: 768px) {
  .header-blue .navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.header-blue .navbar .navbar-brand {
  font-weight: bold;
  color: inherit;
}

.header-blue .navbar .navbar-brand:hover {
  color: #f0f0f0;
}

.header-blue .navbar .navbar-collapse {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 0.5rem;
}

@media (min-width: 768px) {
  .header-blue .navbar .navbar-collapse {
    border-color: transparent;
    margin: 0;
  }
}

.header-blue .navbar .navbar-collapse span .login {
  color: #fff;
  margin-right: 0.5rem;
  text-decoration: none;
}

.header-blue .navbar .navbar-collapse span .login:hover {
  color: #fff;
}

.header-blue .navbar .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.3);
}

.header-blue .navbar .navbar-toggler:hover,
.header-blue .navbar-toggler:focus {
  background: none;
}

@media (min-width: 768px) {
  .header-blue .navbar-nav .nav-link {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
}

@media (min-width: 992px) {
  .header-blue .navbar-nav .nav-link {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

.header-blue .navbar.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.header-blue .navbar.navbar-light .navbar-nav .nav-link:focus,
.header-blue .navbar.navbar-light .navbar-nav .nav-link:hover {
  color: #fcfeff !important;
  background-color: transparent;
}

.header-blue .navbar .navbar-nav > li > .dropdown-menu {
  margin-top: -5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 2px;
}

.header-blue .navbar .dropdown-menu .dropdown-item:focus,
.header-blue .navbar .dropdown-menu .dropdown-item {
  line-height: 2;
  color: #37434d;
}

.header-blue .navbar .dropdown-menu .dropdown-item:focus,
.header-blue .navbar .dropdown-menu .dropdown-item:hover {
  background: #ebeff1;
}

.header-blue .action-button,
.header-blue .action-button:not(.disabled):active {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 40px;
  color: #fff;
  box-shadow: none;
  text-shadow: none;
  padding: 0.3rem 0.8rem;
  background: transparent;
  transition: background-color 0.25s;
  outline: none;
}

.header-blue .action-button:hover {
  color: #fff;
}

.header-blue .navbar .form-inline label {
  color: #d9d9d9;
}

.header-blue .navbar .form-inline .search-field {
  display: inline-block;
  width: 80%;
  background: none;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  color: #fff;
  box-shadow: none;
  color: inherit;
  transition: border-bottom-color 0.3s;
}

.header-blue .navbar .form-inline .search-field:focus {
  border-bottom: 1px solid #ccc;
}

.header-blue .hero {
  margin-top: 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .header-blue .hero {
    margin-top: 60px;
    text-align: left;
  }
}

.header-blue .hero h1 {
  color: #fff;
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 300;
  line-height: 1.4;
}

@media (min-width: 992px) {
  .header-blue .hero h1 {
    margin-top: 190px;
    margin-bottom: 24px;
    line-height: 1.2;
  }
}

.header-blue .hero p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 300;
}

.header-blue .phone-holder {
  text-align: right;
}

.header-blue div.iphone-mockup {
  position: relative;
  max-width: 300px;
  margin-top: 172px;
  display: inline-block;
}

.header-blue .iphone-mockup img.device {
  width: 100%;
  height: auto;
}

.header-blue .iphone-mockup .screen {
  position: absolute;
  width: 88%;
  height: 77%;
  top: 12%;
  border-radius: 2px;
  left: 6%;
  border: 1px solid #444;
  background-color: #aaa;
  overflow: hidden;
  /* background: url(../../assets/img/screen-content-iphone-6.jpg); */
  background-size: cover;
  background-position: center;
}

.header-blue .iphone-mockup .screen:before {
  content: "";
  background-color: #fff;
  position: absolute;
  width: 70%;
  height: 140%;
  top: -12%;
  right: -60%;
  transform: rotate(-19deg);
  opacity: 0.2;
}

.module-border-wrap {
  max-width: 190px;
  padding: 1rem;
  position: relative;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(113.23deg, #c0884a 13.69%, #e9cf7e 75.09%);
  padding: 3px;
  border-radius: 50%;
  overflow: hidden;
}

.module {
  background: #222;
  color: white;
  /* padding: 35px; */
  border-radius: 50%;
}
.module img {
  border-radius: 50%;
  padding: 2px;
}
.team_section {
  width: fit-content;
  padding: 10px 10px;
}
.team_section {
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 10px 0px;
}
.team_section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.team_card h4 {
  margin: 0;
  padding: 8px 0px;
  padding-bottom: 8px;
  padding-bottom: 0;
}
.team_card p {
  margin-bottom: 10px !important;
  color: #8b8484 !important;
}
.team_card a i {
  font-size: 25px;
  color: #fff;
}
.team_card {
  margin: 0 30px;
}
.Disclaimer {
  color: #c1c1c1;
  font-size: 12px;
  text-align: center;
  margin-bottom: 0;
}
.Disclaimer span {
  color: #fff;
  font-size: 14px;
  margin-right: 5px;
}

.con-form label {
  color: #fff;
  font-size: 12px;
}
.con-form .form-control {
  border: 1px solid #707070;
  border-radius: 5px;
  font-size: 12px;
  height: 40px;
  background: #000;
  color: #fff;
}
textarea {
  height: auto !important;
}
.button_roee {
  width: 28%;
  padding: 9px 3px;
  margin-top: 13px !important;
}
.con-form label span {
  color: red;
}
.title_aa_heade {
  text-align: center;
  color: #fff;
  padding: 30px 0px;
  padding-bottom: 10px;
}
.contact_mail {
  padding: 31px 12px;
}
.contact_mail a {
  color: #fff;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
